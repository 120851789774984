import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import {
  BackToTop,
  HeaderItem,
  NavigateMenu,
  NavigateMenuIcon,
  NavigateMenuItem,
  PreviousContain,
  RCHeader,
  RCTitle,
  SignButton,
} from './rc2024.atom'
import { SEO, Toast, Billboard } from '@renderbus/common/components'
import PullIcon from '../images/render-contest/icon_pull.png'
import UpIcon from '../images/render-contest/icon_up.png'
import { graphql, useStaticQuery } from 'gatsby'
import {
  AuthorInfo,
  Banner,
  BreadCrumbs,
  Content,
  Description,
  Divider,
  Footer,
  PopoverWrapper,
  Sidebar,
  SidebarItem,
  Title,
  VideoContain,
  WorkDescription,
  Rc2024GalleryStyle,
  VideoContainMb,
  FooterBar,
  PopoverMbWrapper,
  QRCodeItemMbWrap,
  CopyTipWrap,
  QRCodeWrap,
} from './rc2024-detail.atom'
import WorkViewEye from '../images/render-contest/work-view-eye.png'
import WorkAuthorAvatar from '../images/render-contest/work-author-avatar.png'
import BreadCrumbsArrowIcon from '../images/render-contest/work-bread-crumbs-arrow.png'
import VoteIcon from '../images/render-contest/vote.png'
import VoteHoverIcon from '../images/render-contest/vote-hover.png'
import VoteMbIcon from '../images/render-contest/work-vote-icon-mb.png'
import ShareIcon from '../images/render-contest/share.png'
import ShareMbIcon from '../images/render-contest/work-share-icon-mb.png'
import ShareHoverIcon from '../images/render-contest/share-hover.png'
import ToTopIcon from '../images/render-contest/to-top.png'
import ToTopHoverIcon from '../images/render-contest/to-top-hover.png'
import FavoriteAwardVote from '../images/render-contest/favorite-award-vote.png'
import MostPopularAwardVote from '../images/render-contest/most-popular-award-vote.png'
import PoliceIcon from '../../../common/components/footer/images/police.png'
import { allRcWorkList } from '../constant/rc-result/2024-result'
import { useMedia } from 'use-media'
import { FooterDivider } from './rc2024-gallery.atom'
import QRCode from 'qrcode.react'

const headerItemList = [
  {
    label: '首页',
    link: '/rc2024.html#rc-top',
  },
  {
    label: '赛事详情',
    link: '/rc2024.html#rc-time',
  },
  {
    label: '赛事资料',
    link: '/rc2024.html#rc-content',
  },
  {
    label: '常见问题',
    link: '/rc2024.html#rc-design',
  },
  {
    label: '往届回顾',
  },
]

const mobileMenuItemList = [
  {
    label: '大赛引言',
    link: '/rc2024.html#rc-words',
  },
  {
    label: '大赛时间',
    link: '/rc2024.html#rc-time',
  },
  {
    label: '面向群体',
    link: '/rc2024.html#rc-group',
  },
  {
    label: '大赛奖品',
    link: '/rc2024.html#rc-award',
  },
  {
    label: '评委阵容',
    link: '/rc2024.html#rc-judges',
  },
  {
    label: '大赛内容',
    link: '/rc2024.html#rc-content',
  },
  {
    label: '设计说明',
    link: '/rc2024.html#rc-design',
  },
  {
    label: '关于大赛',
    link: '/rc2024.html#rc-about',
  },
]

function RenderContest2024Detail(props) {
  const {
    pageContext: { detailId },
  } = props
  const location = useLocation()
  const isMobile = useMedia({ maxWidth: 768 })
  const [showPrevious, setShowPrevious] = useState(false)
  const [detailInfo, setDetailInfo] = useState({})
  const [viewCount, setViewCount] = useState(0)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showVoteMb, setShowVoteMb] = useState(false)

  useEffect(() => {
    let info = allRcWorkList.find((item) => item.id === detailId)
    setDetailInfo(info)
  }, [detailId, detailInfo.workLink])

  useEffect(() => {
    detailInfo.workLink &&
      axios({
        method: 'post',
        url: 'https://www.renderbus.com/api/rc/rc-result-2024/add-view',
        data: {
          workLink: detailInfo.workLink,
        },
      })
        .then((response) => {
          const { data } = response
          if (data) {
            setViewCount(data.viewCount)
          }
        })
        .catch((err) => {
          console.log(err)
          return
        })
        .finally(() => {
          return
        })
  }, [detailInfo.workLink])

  function handleScroll() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }

  function copyUrl() {
    let text = location.href
    const el = document.createElement('input')
    el.setAttribute('value', text)
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    Toast.info(<CopyTipWrap>地址复制成功</CopyTipWrap>)
  }

  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "render-contest/work-detail-banner.png" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { eq: "index/spring-2024-banner-mb-bg.png" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script>
          {`
            var _hmt = _hmt || [];
            (function() {
              var hm = document.createElement("script");
              hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
              var s = document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(hm, s);
            })();
          `}
        </script>
      </Helmet>
      <SEO
        title={`${detailInfo?.workName} - 第三届瑞云3D渲染动画创作大赛 - 瑞云科技`}
        keywords={`${detailInfo?.name},${detailInfo?.workName},瑞云渲染大赛`}
        description={`${detailInfo?.workDescription?.slice(
          0,
          Math.min(80, detailInfo?.workDescription?.length),
        )}...`}
        shareImgSrc={detailInfo?.coverLink}
        sharePostSlug='rc2024-detail.html'
      />
      <Rc2024GalleryStyle />
      <RCHeader>
        {headerItemList.map((item) =>
          item.label === '往届回顾' ? (
            <HeaderItem
              key={item.label}
              className='previous-item'
              target={item.target}
              onClick={() => {
                setShowPrevious(!showPrevious)
              }}
              onMouseEnter={() => {
                setShowPrevious(true)
              }}
              onMouseLeave={() => {
                setShowPrevious(false)
              }}
            >
              {item.label}
              <img
                src={showPrevious ? UpIcon : PullIcon}
                alt=''
                onClick={() => {
                  setShowPrevious(!showPrevious)
                }}
              />
              <div className='interval'></div>
              {showPrevious && (
                <PreviousContain>
                  <a href='https://www.rayvision.com/rrc.html' rel='nofollow'>
                    第一届渲染大赛
                  </a>
                  <a href='https://www.renderbus.com/rc.html' rel='nofollow'>
                    第二届渲染大赛
                  </a>
                </PreviousContain>
              )}
            </HeaderItem>
          ) : (
            <HeaderItem key={item.label} href={item.link} rel={item.rel} target={item.target}>
              {item.label}
            </HeaderItem>
          ),
        )}
        <RCTitle className='hideInPC'>
          <p>第三届瑞云3D渲染动画创作大赛</p>
          <p>2023.11.24 - 2024.02.24</p>
        </RCTitle>
        <SignButton href='/rc2024' target='_blank' rel='nofollow'>
          参赛作品展
        </SignButton>
        <NavigateMenuIcon
          isActive={!showMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <NavigateMenu isActive={showMobileMenu} showPCMenu={false}>
          <div className='menu-container'>
            {mobileMenuItemList.map((item) => (
              <NavigateMenuItem
                href={item.link}
                key={item.label}
                onClick={() => {
                  setShowMobileMenu(false)
                }}
                isActive={false}
              >
                {item.label}
              </NavigateMenuItem>
            ))}
            <BackToTop
              href='#rc-top'
              onClick={() => {
                setShowMobileMenu(false)
              }}
            >
              回到顶部
            </BackToTop>
          </div>
        </NavigateMenu>
      </RCHeader>
      <Banner>
        <Billboard fluid={[data.banner.childImageSharp.fluid]} />
      </Banner>
      <VideoContainMb>
        <video key={`pc_${detailInfo?.id}`} playsInline controls={true} preload='auto'>
          <source src={detailInfo.workLink} />
        </video>
      </VideoContainMb>
      <Content>
        <BreadCrumbs>
          <a href='/rc2024.html'>首页</a>
          <img src={BreadCrumbsArrowIcon} alt='BreadCrumbsArrowIcon' />
          <a href='/rc2024'>全部作品</a>
          <img src={BreadCrumbsArrowIcon} alt='BreadCrumbsArrowIcon' />
          <span>详情</span>
        </BreadCrumbs>
        <Title>{detailInfo.workName}</Title>
        {!isMobile && (
          <Description>
            <span>原创作品</span>
            <Divider type='vertical' height='12px' />
            <span>作品版权由作者解释</span>
            <Divider type='vertical' height='12px' />
            <div>
              <img src={WorkViewEye} alt='eye' />
              {viewCount}
            </div>
          </Description>
        )}
        <AuthorInfo>
          <img src={WorkAuthorAvatar} alt='avatar' />
          {detailInfo.name}
        </AuthorInfo>
        {isMobile && (
          <Description>
            <span>原创作品</span>
            <Divider type='vertical' height='12px' />
            <span>作品版权由作者解释</span>
            <Divider type='vertical' height='12px' />
            <div>
              <img src={WorkViewEye} alt='eye' />
              {viewCount}
            </div>
          </Description>
        )}
        <VideoContain>
          <WorkDescription>{detailInfo.workDescription}</WorkDescription>
          <video key={`pc_${detailInfo?.id}`} playsInline controls={true} preload='auto'>
            <source src={detailInfo.workLink} />
          </video>
        </VideoContain>
      </Content>
      <FooterDivider type='horizontal' width='100%' borderColor='#E5E5E5' margin='60px 0 0' />
      <Footer>
        <p>
          <img src={PoliceIcon} alt='' />© 2024
          <a href='https://www.rayvision.com/' rel='nofollow'>
            深圳市瑞云科技股份有限公司
          </a>
          <a
            href='https://beian.miit.gov.cn/#/Integrated/index'
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            粤ICP备12028569号
          </a>
        </p>
        <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
      </Footer>
      <Sidebar>
        <SidebarItem iconWidth={24} iconHeight={27}>
          <img src={VoteIcon} alt='' data-icon />
          <img src={VoteHoverIcon} alt='' data-icon-hover />
          <PopoverWrapper>
            <img src={FavoriteAwardVote} alt='wechat' />
            <div>
              微信扫码，行业
              <br />
              观众喜爱奖投票
            </div>
            <img src={MostPopularAwardVote} alt='wechat' />
            <div>
              微信扫码，票圈
              <br />
              最受欢迎奖投票
            </div>
          </PopoverWrapper>
        </SidebarItem>
        <SidebarItem iconWidth={26} iconHeight={26}>
          <img src={ShareIcon} alt='' data-icon />
          <img src={ShareHoverIcon} alt='' data-icon-hover />
          <PopoverWrapper short>
            <QRCode
              id='qrCode'
              value={location.href}
              size={88} // 二维码的大小
              fgColor='#000000' // 二维码的颜色
              style={{
                margin: 'auto',
                padding: '6px',
                background: '#fff',
              }}
            />
            <div>扫一扫分享至微信</div>
          </PopoverWrapper>
        </SidebarItem>
        <SidebarItem iconWidth={23} iconHeight={23} onClick={() => handleScroll()}>
          <img src={ToTopIcon} alt='' data-icon />
          <img src={ToTopHoverIcon} alt='' data-icon-hover />
        </SidebarItem>
      </Sidebar>
      <FooterBar>
        <img src={ShareMbIcon} className='share-mb-icon' onClick={() => copyUrl()} alt='' />
        <img
          src={VoteMbIcon}
          className='vote-mb-icon'
          onClick={() => setShowVoteMb(!showVoteMb)}
          alt=''
        />
        {showVoteMb && (
          <PopoverMbWrapper>
            <QRCodeItemMbWrap>
              <QRCodeWrap>
                <img src={FavoriteAwardVote} alt='wechat' />
              </QRCodeWrap>
              <div>
                {isMobile ? '长按识别二维码' : '微信扫码，行业'}
                <br />
                {isMobile ? '行业观众喜爱奖投票' : '观众喜爱奖投票'}
              </div>
            </QRCodeItemMbWrap>
            <QRCodeItemMbWrap>
              <QRCodeWrap>
                <img src={MostPopularAwardVote} alt='wechat' />
              </QRCodeWrap>
              <div>
                {isMobile ? '长按识别二维码' : '微信扫码，票圈'}
                <br />
                {isMobile ? '票圈最受欢迎奖投票' : '最受欢迎奖投票'}
              </div>
            </QRCodeItemMbWrap>
          </PopoverMbWrapper>
        )}
      </FooterBar>
    </>
  )
}

export default RenderContest2024Detail
