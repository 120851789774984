import { Media, hideSMUp, mbSize } from '@renderbus/common/theme'
import { Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

export const Rc2024GalleryStyle = createGlobalStyle`
  html {
    background-color: #F3F8FF;
  }
  .hideInPC {
    ${hideSMUp}
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
`

export const Header = styled.div`
  width: 100%;
  height: 62px;
  background: linear-gradient(180deg, #0d276d 0%, #061438 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${mbSize(40)};
  ${Media.lessThan(Media.small)} {
    margin-top: 44px;
    height: calc(100vw * 200 / 375);
  }
`

export const WorkBigTitleImg = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    width: ${mbSize(684)};
    height: ${mbSize(192)};
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const WorkBannerButtonImg = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    width: ${mbSize(260)};
    height: ${mbSize(58)};
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const TabsWrap = styled.div`
  width: 100%;
  height: 72px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 96px;
  ${Media.lessThan(Media.small)} {
    height: fit-content;
    margin-top: -1px;
  }
`

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 430px;
  height: 72px;
  background: ${({ active }) =>
    active
      ? 'linear-gradient(270deg, #e1f0ff 0%, #ebf5ff 0%, #4fa2f0 81%, #f4d096 100%)'
      : 'unset'};
  font-size: 18px;
  font-weight: 500;
  color: ${({ active }) => (active ? '#010613' : '#53A4F0')};
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    width: 50vw;
    height: ${mbSize(80)};
    font-size: ${mbSize(24)};
    font-weight: 400;
  }
`

export const SearchWrap = styled.div`
  width: 244px;
  height: 40px;
  background: #0d2447;
  border-radius: 4px 4px 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  input {
    outline: none;
    border: 0px;
    height: 24px;
    color: #618ab6;
    font-size: 13px;
    width: 190px;
  }
  img {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const SearchMbWrap = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(702)};
    height: ${mbSize(76)};
    background: #ffffff;
    border-radius: ${mbSize(200)};
    display: flex;
    margin: ${mbSize(32)} auto 0;
    justify-content: center;
    align-items: center;
    gap: 5px;
    input {
      outline: none;
      border: 0px;
      height: ${mbSize(48)};
      color: #5e5e5e;
      font-size: ${mbSize(28)};
      width: ${mbSize(595)};
    }
    img {
      width: ${mbSize(32)};
      height: ${mbSize(32)};
    }
  }
`

export const WorkWrap = styled.div`
  max-width: 1140px;
  margin: auto;
  padding-top: 80px;
  min-height: 500px;
  ${Media.lessThan(Media.small)} {
    min-height: ${mbSize(600)};
    padding: ${mbSize(32)} ${mbSize(24)} 0;
  }
`

export const WorkHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  border-bottom: 1px solid #e5e5e5;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const WorkHeaderMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`

export const WorkHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #3d3d3d;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(32)};
  }
`

export const WorkHeaderTotal = styled.div`
  margin-left: 8px;
  font-size: 32px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 500;
  color: #3d3d3d;
  ${Media.lessThan(Media.small)} {
    margin-left: 0;
    font-size: ${mbSize(32)};
  }
`

export const WorkSortWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 23px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const Divider = styled.div`
  width: ${({ type, width }) => (type === 'horizontal' ? width : '0px')};
  height: ${({ type, height }) => (type === 'horizontal' ? '0px' : height)};
  border: 1px solid ${({ borderColor }) => (borderColor ? borderColor : '#d8d8d8')};
  margin: ${({ margin }) => (margin ? margin : 'unset')};
`

export const SortItem = styled.div`
  font-size: 14px;
  font-weight: 350;
  color: ${({ active }) => (active ? '#2F9BFF' : '#999999')};
  cursor: pointer;
  transition: all 0.3s;
`

export const WorkListWrap = styled.div`
  display: flex;
  gap: 33px;
  flex-wrap: wrap;
  margin-top: 30px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(40)};
    gap: ${mbSize(26)};
  }
`

export const WorkItem = styled(Link)`
  width: 260px;
  cursor: pointer;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(338)};
    background: #ffffff;
    border-radius: ${mbSize(20)};
  }
`

export const WorkInfoContainer = styled.div`
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(16)};
  }
`

export const WorkImg = styled.div`
  width: 260px;
  height: 207px;
  border-radius: 10px;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(338)};
    height: ${mbSize(200)};
    border-radius: ${mbSize(20)} ${mbSize(20)} 0 0;
  }
`

export const WorkName = styled.div`
  font-size: 16px;
  color: #3d3d3d;
  font-family: Source Han Sans, Source Han Sans;
  margin: 20px 0 8px;
  ${Media.lessThan(Media.small)} {
    margin: 0 0 ${mbSize(15)};
    font-size: ${mbSize(24)};
    font-weight: 700;
  }
`

export const WorkInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-family: Source Han Sans, Source Han Sans;
`

export const WorkUserInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 16px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    font-weight: 350;
    > img {
      width: ${mbSize(36)};
      height: ${mbSize(36)};
      margin-right: ${mbSize(8)};
    }
  }
`

export const WorkView = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  > img {
    width: 18px;
    height: 11px;
    border-radius: 50%;
    margin-right: 4px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(16)};
    font-weight: 350;
    > img {
      width: ${mbSize(16)};
      height: ${mbSize(10)};
      margin-right: ${mbSize(6)};
    }
  }
`

export const Footer = styled.div`
  width: 1200px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  font-size: 14px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 350;
  color: #666666;
  a:hover {
    color: #5195f5;
  }
  img {
    margin: 10px 11px 0 5px;
    transform: translate(0, -50%);
  }
  ${Media.lessThan(Media.small)} {
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: space-around;
    font-size: 2.39vw;
    padding-top: ${mbSize(40)};
    padding-bottom: ${mbSize(40)};
    p {
      margin: auto;
    }
    img {
      margin: 1.6vw 3.33vw 0 5px;
      width: 1.86vw;
    }
  }
`

export const PaginationWrapper = styled.div`
  margin: 76px auto 120px;
  button {
    width: 29px;
    height: 29px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    color: #9c9c9c;
    border: none;
    :hover {
      color: #000000;
      background: #ffffff;
      border: none;
    }
    &.active-btn {
      color: #000000;
    }
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const SeeMoreButton = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
    margin: ${mbSize(48)} auto 0;
    width: ${mbSize(264)};
    height: ${mbSize(70)};
    border-radius: ${mbSize(4)};
    border: ${mbSize(2)} solid #757575;
    font-size: ${mbSize(24)};
  }
`

export const FooterDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 60px 0 0;
  ${Media.lessThan(Media.small)} {
    margin: ${mbSize(60)} 0 0;
  }
`

export const EmptyWrap = styled.div`
  width: 100%;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 350;
  font-size: 16px;
  color: #666666;
  text-align: center;
  > img {
    width: 180px;
    height: 129px;
    margin-bottom: 20px;
  }
  ${Media.lessThan(Media.small)} {
    min-height: ${mbSize(600)};
    font-size: ${mbSize(28)};
    > img {
      width: ${mbSize(277)};
      height: ${mbSize(199)};
      margin-bottom: ${mbSize(26)};
    }
  }
`
