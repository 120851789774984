import styled, { createGlobalStyle } from 'styled-components'
import WorkFixedButtonBg from '../images/render-contest/work-fixed-button-bg.png'
import SidebarHoverBg from '../images/render-contest/sidebar-hover-bg.png'
import SidebarMbBg from '../images/render-contest/sidebar-mb-bg.png'
import SidebarHoverShortBg from '../images/render-contest/sidebar-hover-short-bg.png'
import { Media, hideSMUp, mbSize } from '@renderbus/common/theme'
import { ToastWrap } from '@renderbus/common/components/toast/atoms'
import ToastSuccess from '../images/render-contest/toast-success.png'

export const Rc2024GalleryStyle = createGlobalStyle`
  html {
    background-color: #F3F8FF;
  }
  .hideInPC {
    ${hideSMUp}
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  ${ToastWrap} {
    display: flex;
    align-items: center;
    width: 327px;
    height: 47px;
    line-height: 47PX;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.05);
    border-radius: 2px 2px 2px 2px;
    color: #2F2F2F;
    font-size: 14px;
    top: 50% !important;
    &::before {
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${ToastSuccess});
      background-size: 100%;
      margin: 0 12px 0 10px;
    }
    &::after{
      display: inline-block;
      content: '';
      position: absolute;
      width: 3px;
      height: 39px;
      background: #219B6A;
      border-radius: 10px 10px 10px 10px;
    }
  }
`

export const Banner = styled.div`
  position: absolute;
  height: 500px;
  width: 100%;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const Content = styled.div`
  width: 1200px;
  margin: auto;
  position: relative;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(40)};
    width: 100vw;
    padding: 0 ${mbSize(32)};
    min-height: ${mbSize(470)};
  }
`

export const BreadCrumbs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 350;
  color: #217dd3;
  margin-top: 30px;
  > a {
    cursor: pointer;
  }
  > img {
    width: 16px;
    height: 16px;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const Title = styled.h1`
  font-size: 42px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 700;
  color: #333333;
  text-align: center;
  margin: 30px 0;
  ${Media.lessThan(Media.small)} {
    text-align: left;
    font-size: ${mbSize(32)};
    font-weight: 500;
    margin: 0 0 ${mbSize(24)};
  }
`

export const Description = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 350;
  color: #848484;
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    > img {
      width: 20px;
      height: 13px;
    }
  }
  ${Media.lessThan(Media.small)} {
    justify-content: left;
    font-weight: 350;
    font-size: ${mbSize(24)};
    color: #999999;
    gap: ${mbSize(32)};
    > div {
      gap: ${mbSize(8)};
      > img {
        width: ${mbSize(32)};
        height: ${mbSize(18)};
      }
    }
  }
`

export const Divider = styled.div`
  width: ${({ type, width }) => (type === 'horizontal' ? width : '1px')};
  height: ${({ type, height }) => (type === 'horizontal' ? '1px' : height)};
  background: ${({ borderColor }) => (borderColor ? borderColor : '#d8d8d8')};
  margin: ${({ margin }) => (margin ? margin : 'unset')};
`

export const AuthorInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 350;
  color: #8c8c8c;
  margin: 32px 0 80px;
  > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  ${Media.lessThan(Media.small)} {
    text-align: left;
    font-size: ${mbSize(24)};
    font-weight: 350;
    color: #999999;
    justify-content: left;
    margin: ${mbSize(24)} 0;
    > img {
      width: ${mbSize(36)};
      height: ${mbSize(36)};
      border-radius: 50%;
    }
  }
`

export const VideoContain = styled.div`
  width: 1200px;
  height: auto;
  margin: auto;
  position: relative;
  video {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const VideoContainMb = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
    width: 100vw;
    height: auto;
    margin: auto;
    position: relative;
    cursor: pointer;
    margin-top: 45px;
    video {
      width: 100%;
      height: 100%;
    }
  }
`

export const Footer = styled.div`
  width: 1200px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  font-size: 14px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 350;
  color: #666666;
  a:hover {
    color: #5195f5;
  }
  img {
    margin: 10px 11px 0 5px;
    transform: translate(0, -50%);
  }
  ${Media.lessThan(Media.small)} {
    width: 100vw;
    height: auto;
    flex-direction: column;
    justify-content: space-around;
    font-size: 2.39vw;
    padding-top: ${mbSize(40)};
    padding-bottom: ${mbSize(40)};
    margin-bottom: ${mbSize(122)};
    p {
      margin: auto;
    }
    img {
      margin: 1.6vw 3.33vw 0 5px;
      width: 1.86vw;
    }
  }
`

export const Sidebar = styled.div`
  position: fixed;
  right: 40px;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const PopoverWrapper = styled.div`
  position: absolute;
  width: 131px;
  height: ${({ short }) => (short ? '135px' : '300px')};
  background: ${({ short }) => (short ? `url(${SidebarHoverShortBg})` : `url(${SidebarHoverBg})`)};
  background-size: 100%;
  top: -18px;
  left: -16px;
  transform: translate(-100%, 0);
  border-radius: 6px;
  color: white;
  padding: 16px 10px 0 0;
  display: none;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  img {
    width: 88px;
    height: 88px;
  }
  img:nth-child(n + 2) {
    margin-top: 12px;
  }
  div {
    margin-top: 4px;
  }
`

export const PopoverMbWrapper = styled.div`
  position: absolute;
  width: ${mbSize(648)};
  height: ${mbSize(396)};
  background: url(${SidebarMbBg});
  background-size: 100%;
  top: ${mbSize(-420)};
  right: ${mbSize(30)};
  color: white;
  padding: ${mbSize(35)} ${mbSize(48)};
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
`

export const SidebarItem = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-image: url(${WorkFixedButtonBg});
    background-size: 100%;
  }
  :hover {
    ::before {
      filter: brightness(200%);
      transition: all 0.2s;
    }
  }
  > img {
    width: ${({ iconWidth }) => `${iconWidth}px`};
    width: ${({ iconHeight }) => `${iconHeight}px`};
  }
  img[data-icon-hover] {
    display: none;
  }
  :hover {
    img[data-icon] {
      display: none;
    }
    img[data-icon-hover] {
      display: unset;
    }
    ${PopoverWrapper} {
      display: block;
    }
  }
`

export const WorkDescription = styled.div`
  margin-bottom: 20px;
  width: 1200px;
  font-size: 16px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 350;
  color: #4f4f4f;
  line-height: 40px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    font-weight: 350;
    font-size: ${mbSize(28)};
    color: #010101;
    margin-top: ${mbSize(38)};
    line-height: ${mbSize(60)};
  }
`

export const FooterBar = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100vw;
    height: ${mbSize(122)};
    background: #ffffff;
    display: flex;
    justify-content: end;
    align-items: center;
    .share-mb-icon {
      margin-right: ${mbSize(48)};
      width: ${mbSize(88)};
      height: ${mbSize(88)};
    }
    .vote-mb-icon {
      margin-right: ${mbSize(32)};
      width: ${mbSize(162)};
      height: ${mbSize(84)};
    }
  }
`

export const QRCodeWrap = styled.div`
  border: 1px solid #517a9f;
  padding: ${mbSize(8)};
  > img {
    width: ${mbSize(224)};
    height: ${mbSize(224)};
  }
`

export const QRCodeItemMbWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 400;
  font-size: ${mbSize(24)};
  color: #ffffff;
  text-align: center;
`

export const CopyTipWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`
